import React, { Component } from "react";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { withRouter } from "react-router";

import {
  fetchWishListItems,
  deleteWishListItems,
} from "../../actions/Products";

import {
  updateWishlisttNum,
  updateWishProductNum,
  updateWishProductNumSum,
  
} from "../../actions/OtherActions";

import {
  removeFromWishList,
  updateWishListCount,
} from "../../actions/WishlistActions";

import Header from "../Navbar/Header";

import axios from 'axios';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Wishlist extends Component {
  state = {
    productCount: 0,
    productName: "",
    productId: "",
    single_productCount: 1,
    TotalProductsCount:0,
  };

  componentDidMount() {
    if(!localStorage.profile){
      alert("Please Login");
      this.props.history.push('/signin')
    }
    
    // else if(localStorage.profile&& !localStorage.myWishList){
      
    //   let userId = JSON.parse(localStorage.getItem("profile")).user._id
    //   axios.get(`/wishlist/checkwishlist/${userId}`).then((wish)=>{
    //               console.log(wish.data.data.wishListArray);
    //             localStorage.setItem("myWishList",JSON.stringify(wish.data.data.wishListArray));
    //             localStorage.setItem("wishlength",JSON.stringify(wish.data.data.wishListArray.length))
    //             //alert(JSON.parse(localStorage.getItem("myWishList")).length)
    //             this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList").length),})
    //   }).catch((error)=>{console.log(error)})
          
    // }
    // else if(localStorage.myWishList){
    //   this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList").length)})
    //   localStorage.setItem("wishlength",JSON.parse(localStorage.getItem("myWishList")).length);
        
    // } 
    else{
      return ;
    }
    // const items = { ...localStorage };
    // const ids = JSON.parse(localStorage.getItem("myWishList"))
    //   ? JSON.parse(items.myWishList)
    //   : [];
    // ids.map((val) => {});
    // this.setState({ totalProducts: ids.length });
  }

  renderWishlist() {
    const items = { ...localStorage };
    const id = JSON.parse(localStorage.getItem("myWishList"))
      ? JSON.parse(items.myWishList)
      : [];
    if (id.length >= 1) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <div className="table-responsive">
                <table
                  className="table-striped text-center"
                  cellPadding="5px"
                  width="100%"
                >
                  <thead className="greenBg text-white">
                    <tr className="p-2 text-center">
                      <th>Item</th>
                      <th className="mx-2">Rate</th>
                      <th>Quantity</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">{this.renderProducts()}</tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center ">
              <div className="bordered shadow rounded">
                <h3 className="p-2">Order Summary</h3>
                <hr />
                <p className="p-1">
                  <b>Total Products: </b>
                  {this.renderProductsCount()}
                  {/* {this.props.WishlistCount.count} */}
                </p>
                <hr />
                <p className="p-1">
                  <b>Total Estimated Cost: </b>
                  {this.renderOrderSummary()}
                </p>
                <hr />
                <p className="p-1">
                  {localStorage.getItem("profile") === null ? (
                    <span>
                      Please Login to checkout, <br />
                      <Link
                        to="/signin"
                        className="btn btn-warning btn-block py-2"
                      >
                        click here
                      </Link>
                    </span>
                  ) : (
                    <Link
                      to="/wishlistpaymentform"
                      className="btn btn-success btn-block py-2"
                    >
                      Checkout
                    </Link>
                  )}
                </p>
                <hr />
                <p className="p-1">
                  {/* <Link
                    to="/store"
                    className="btn btn-danger btn-block py-2"
                    onClick={() => {
                      const user_Id = JSON.parse(localStorage.getItem("profile"));
    
                      const userId = user_Id.user._id;
                      axios.put(`/wishlist/clearWishlist/${userId}`)
                      .then((wish)=>{
                        localStorage.removeItem("myWishList");
                        localStorage.removeItem("wishlength");
                        trackPromise(this.props.updateWishlisttNum(0));
                        toast.success("Your cart is successfully cleared");
                      })
                      .catch((error)=>{
                        console.log(error)
                      })
                      
                    }}
                  >
                    Clear Wishlist
                  </Link> */}
                  <span
                    
                    className="btn btn-danger btn-block py-2"
                    onClick={() => {
                      const user_Id = JSON.parse(localStorage.getItem("profile"));
    
                      const userId = user_Id.user._id;
                      axios.patch(`/wishlist/clearWishlist/${userId}`)
                      .then((wish)=>{
                        localStorage.removeItem("myWishList");
                        localStorage.removeItem("wishlength");
                        trackPromise(this.props.updateWishlisttNum(0));
                        toast.success("Your WishList is successfully cleared");
                        this.props.history.replace('/');
                      })
                      .catch((error)=>{
                        console.log(error)
                      })
                     this.props.updateWishlisttNum(0); 
                    }}
                  >
                    Clear Wishlist
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h2 className="text-center text-danger mt-5">
            No Products In Your WishList
          </h2>
          <Link
            to="/store"
            className="btn btn-outline-danger text-center p-3"
            style={{ fontSize: "1.5rem" }}
          >
            Add Now
          </Link>
        </div>
      );
    }
  }

  renderProducts() {
    // console.log(JSON.parse(localStorage.getItem("cartItem")));
    const user_Id = JSON.parse(localStorage.getItem("profile"));
    
    const userId = user_Id.user._id;
    
    const products = JSON.parse(localStorage.getItem("myWishList"))
                        ?JSON.parse(localStorage.getItem("myWishList"))
                        :axios.get(`/wishlist/singlewishlist/${userId}`).then((wish)=>{
                          localStorage.setItem("myWishList",JSON.stringify(wish.data.wishListArray))
                          localStorage.setItem("wishlength",JSON.stringify(wish.data.wishListArray.length))
                          console.log(wish.data.wishListArray)
                          return wish.data.wishListArray;
                        });
    return products.map((item, index) => {
      console.log(item)
      return (
        <tr key={item._id}>
          <td className="d-flex">
            <img src={item.image} alt="Cart Image" width="80px" />
            <h5 className="mx-2 my-auto text-capitalize">{item.name}</h5>
          </td>
          <td>
            <span>{item.price}</span>
          </td>
          <td className="d-flex" style={{ transform: "translateY(-40%)" }}>
            <a
              className="cart_btn1"
              onClick={() => {
                trackPromise(
                  this.props.updateWishProductNum(
                    item._id,
                    item.price,
                    this.props.history
                  )
                );
              }
            }
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-minus"></i>
            </a>
            <input
              type="text"
              min={0}
              name="productCount"
              className="text-center customInput"
              value={item.count}
              onChange={(e) => {
                this.setState({ productCount: e.target.value });
              }}
              disabled
            />
            <a
              onClick={() => {
                
                 trackPromise(
                   this.props.updateWishProductNumSum(
                     item._id,
                     item.price,
                     this.props.history
                   )
                 );
                // const items = { ...localStorage };
                // const data = JSON.parse(localStorage.getItem("myWishList"))
                //   ? JSON.parse(items.myWishList)
                //   : [];

                // data.forEach((v) => {
                //   if (v._id ===item._id) v.count = v.count + 1;
                // });
                // localStorage.setItem("myWishList", JSON.stringify(data));

              }}
              className="cart_btn1 mr-2"
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-plus"></i>
            </a>
          </td>
          <td>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                axios.delete(`/wishlist/fetchSingleWishlistAndDelete/${userId}/${item._id}`).then((wish)=>{
                        alert("deleted successfully");
                        //console.log(wish.data.wishListArray);
                        localStorage.setItem("myWishList",JSON.stringify(wish.data.wishListArray))
                        localStorage.setItem("wishlength",JSON.stringify(wish.data.wishListArray.length));
                        //this.props.updateWishlisttNum();
                        //window.location.reload();
                        this.renderProductsCount();
                     this.setState({TotalProductsCount:wish.data.wishListArray.length})   
                }).catch((error)=>{console.log(error);alert("something went wrong")})
                // trackPromise(this.props.deleteWishListItems(item._id));
                // trackPromise(this.props.removeFromWishList(item._id));
                // trackPromise(this.props.updateWishListCount());
                this.props.updateWishlisttNum();
              }}
            >
              <span>
                <i className="fas fa-trash fa-2x"></i>
              </span>
            </span>
          </td>
        </tr>
       
      );
    });
  }

  renderProductsCount() {
    const items = { ...localStorage };
    const ids = JSON.parse(localStorage.getItem("myWishList"))
      ? JSON.parse(items.myWishList)
      : [];
      //console.log(ids.length);
      
    return ids.length;
  }

  renderOrderSummary() {
    const items = { ...localStorage };
    const price = [];
    const qnt = [];
    const ids = JSON.parse(localStorage.getItem("myWishList"))
      ? JSON.parse(items.myWishList)
      : [];

    for (let i in ids) {
      // console.log(ids[i]);
      price.push(ids[i].price);
      qnt.push(ids[i].count);
    }
    // console.log(price, qnt);
    const total = qnt.reduce(function (r, a, i) {
      return r + a * price[i];
    }, 0);
    return total;
  }

  render() {
     //console.log(this.props.WishlistCount.count);
    return (
      <div>
        {/* {this.state.TotalProductsCount} */}
        <Header />
        <div className="py-2 p-md-0"></div>
        {this.renderWishlist()}
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wishlist: state.wishListItem,
    count: state.productCount,
    WishlistCount: state.WishlistCount
  };
};

export default compose(
  connect(mapStateToProps, {
    fetchWishListItems,
    deleteWishListItems,
    updateWishlisttNum,
    updateWishProductNum,
    updateWishProductNumSum,
    removeFromWishList,
    updateWishListCount,
  })(withRouter(Wishlist))
);
