// import React, { Component,useState,useEffect } from "react";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { PromiseProvider } from "mongoose";
// import axios from 'axios';
// import {Wishcount} from './Wishcount';

// class WishlistLink extends Component {
//   state = { count: 0 };
//   componentDidMount() {
//     const data = { ...localStorage };
//     this.setState({ count: Object.keys(data).length });
//   }

//   componentDidUpdate(prevProps, prevState) {
//     // console.log(prevProps);
//   }

//   render() {
//     // console.log(this.props.cartCount.count);
//     return (
//       <div>
//         <div className="wishlist">
//           <Link to="/cart">
//             <span
//               className="p-1 rounded-circle text-white "
//               style={{ background: "rgb(255, 102, 0)" }}
//             >
//               {this.props.cartCount.count}
//             </span>
//             <img src="images/cart.svg" />
//             <a  className="icon-heart d-block"></a>
//           </Link>
//         </div>
//       </div>
//     );
//   }
// }
// const mapStateToProps = (state) => {
//   return { cartCount: state.cartCount };
// };

// export default connect(mapStateToProps, {})(WishlistLink);

// export function Counter(numbers){
//     alert("ok")
//     alert((numbers)+2)
//    return numbers+2;
// }

// function WishlistLink(props){
//   const [wishCount,setWishCount] = useState(0);
//   // console.log(Wishcount())

//   useEffect(()=>{
//             let count = JSON.parse(localStorage.getItem("wishlength"));
//             console.log(count)
//             setWishCount(count)
//   },[])

//     return(
//         <div>
//             <div className="cartwishList">
//             <Link to="/wishlistPage" >
          
//             {/* <div
//               className="p-1  text-white "
//               style={{ textAlign:"center",width:"24px",height:"24px",margin:"0px",marginTop:"25px",marginLeft:"0px", }}
//             >
//              {2}
//             </div> */}
//             {/* <span
//               className="p-1  text-white cart_count"
//               style={{ }}
//             >
//               {props.count}{this.state.myWishList_Item_count==""?0:this.state.myWishList_Item_count}
//             </span> */}
//             <i className="bi bi-heart-fill" style={{color:"rgb(255, 102, 0)",fontSize:"30px",}}></i>
//             <span className="fa-layers-text " style={{color:"#ffff",postion:"absolute",top:"45%"}}>{props.count}</span>


//           </Link>
//           </div>
//         </div>
//     );

// }

// const mapStateToProps = (state) => {
//   console.log(state.WishlistCount)
//   console.log({wishListCount: state.WishlistCount})
//     return state.WishlistCount ;
//   };
  
// export default connect(mapStateToProps,{})(WishlistLink);
//export  default connect(Wishcount)(WishlistLink);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class WishlistLink extends Component {
  state = { count:this.props.WishlistCount.count };
  
  componentDidMount() {
    const data = { ...localStorage };
    //this.setState({ count: Object.keys(data).length });
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps);
  }

  render() {
    //  console.log(this.props.WishlistCount.count);
    //  alert(this.props.WishlistCount.count)
    return (
      <div>
        <div className="cartwishList" style={{}}>
          <Link to="/wishlistPage" >
            
		      <i className="bi bi-heart-fill " style={{color:"rgb(255, 102, 0)",fontSize:"30px",}}></i>
          <span className="wish_count"style={{color:"#ffff",}}>{this.props.WishlistCount.count}</span>
    
    

		{/* <div className="box-content" style={{color:"#ffff",zIndex:10000000}}>
      		
        
        
       
		</div> */}
            {/* <i className="bi bi-heart-fill big-centered-icon" style={{color:"rgb(255, 102, 0)",fontSize:"30px",postion:"absolute",top:"45%",zIndex:-1000000}}></i>
            <span
              className="p-1"
              style={{color:"#ffff",postion:"absolute",top:"150%",
              right:"20%",
              
              border:"solid red 2px",
              paddingBottom:"0px",
              
              zIndex:1000000  }}
            >
              <span style={{
              border:"solid red 2px",
              marginBottom:"0px"
                }}>{this.props.WishlistCount.count}</span>
              
            </span> */}
             {/* <span className=" " style={{color:"#ffff",}}>{this.props.WishlistCount.count}</span>
             <span className=" " style={{color:"red",}}>{this.props.WishlistCount.count}</span>
            <i className="bi bi-heart-fill big-centered-icon" style={{color:"rgb(255, 102, 0)",fontSize:"30px"}}></i> */
            }
             {/* <img src="images/heart-fill.svg" /> */}
             {/* style={{textAlign:"center",position:"relative",margin:"8px",width: "100%",paddingBottom: "75%",border:"solid re 2px"}} */}
          </Link>
          
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log(state.WishlistCount)
  return { WishlistCount: state.WishlistCount };
};

export default connect(mapStateToProps)(WishlistLink);
