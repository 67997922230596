// import React, { Component } from "react";
// import { trackPromise } from "react-promise-tracker";
// import { connect } from "react-redux";
// import Moment from "react-moment";
// import "moment-timezone";
// import { Link } from "react-router-dom";
// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file
// import { Calendar } from "react-date-range";
// import { DateRangePicker } from "react-date-range";
// import $ from "jquery";
// // import DatePicker from "react-date-picker";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// import { fetchOrders, createOrder } from "../../../actions/paymentActions";
// import AdminHeader from "../AdminHeader";
// import { ExportToExcel } from "../Orders/ExportToExcel";

// import axios from 'axios';

// class WishlistDetails extends Component {
//   state = {
//     update: true,
//     search: "",
//     fileName:
//       "MUG Sales Report " +
//       new Date().getDate() +
//       "-" +
//       parseInt(new Date().getMonth() + 1) +
//       "-" +
//       new Date().getFullYear() +
//       " " +
//       new Date().getHours() +
//       ":" +
//       new Date().getMinutes() +
//       ":" +
//       new Date().getSeconds(),

//     data: {},
//     startDate: new Date(),
//     endDate: new Date(),
//     filteredArray: [],
//     address: "",
//     city: "",
//     phoneNo: "",
//     pincode: "",
//     country: "",
//     state: "",
//     company: "",
//     username: "",
//     userEmail: "",
//     // userId: "61b239f77b1a7e100bf71047",
//     userId: "",
//     orderItems: [],
//     payment: [],
//     itemsPrice: "",
//     totalPrice: "",
//     id: "",
//     change: false,
//     paymentMode: "",
//     bgColor: "",
//     discountPrice: "",
//     shippingPrice: "",
//     wishlistArr:[],
//   };

//    componentDidMount() {
//     trackPromise(this.props.fetchOrders());
//    axios.get('http://localhost:5000/wishlist/')
//     .then((res)=>{
//                     console.log(res);
//                     let arry=res.data;
//                     console.log(arry);
//                     sessionStorage.setItem("userswishlist",JSON.stringify(res.data))
//                     this.setState({wishlistArr:res.data})
//                     return res.data
//     }).catch((error)=>{console.log(error)}
//     )
    
//     this.setState({ update: false });
//     // console.log(JSON.parse(localStorage.getItem("myWishList")) ? true : false);

//     // const profile = localStorage.getItem("twk_token_621770fd1ffac05b1d7b8539");
//     // console.log(profile);
//     const items = { ...localStorage };
//     const price = [];
//     const qnt = [];
//     const ids = JSON.parse(localStorage.getItem("myWishList"))
//       ? JSON.parse(items.myWishList)
//       : [];

//     for (let i in ids) {
//       // console.log(ids[i]);
//       price.push(ids[i].price);
//       qnt.push(ids[i].count);
//     }
//     // console.log(price, qnt);
//     const total = qnt.reduce(function (r, a, i) {
//       return r + a * price[i];
//     }, 0);

//     // this.setState({
//     //   userEmail: profile.email,
//     //   phoneNo: profile.mobile,
//     //   username: profile.name,
//     //   userId: profile._id,
//     //   totalPrice: total,
//     //   orderItems: JSON.parse(localStorage.getItem("myWishList")),
//     // });

//     if (total >= 10000) {
//       const discount = total - 10000;
//       const discountPer = 10000 - 10000 * 0.15;
//       const finalCost = discount + discountPer;
//       this.setState({ discountPrice: Math.ceil(finalCost) });
//     } else {
//       this.setState({ discountPrice: Math.ceil(total - total * 0.15) });
//     }

//     if (total >= 10000) {
//       const discount = total - 10000;
//       const discountPer = 10000 - 10000 * 0.15;
//       const finalCost = discount + discountPer;

//       if (total >= 1000) {
//         this.setState({ itemsPrice: Math.ceil(finalCost), shippingPrice: 0 });
//       } else {
//         this.setState({
//           itemsPrice: Math.ceil(finalCost) + 99,
//           shippingPrice: 99,
//         });
//       }
//     } else {
//       if (total >= 1000) {
//         this.setState({
//           itemsPrice: Math.ceil(total - total * 0.15),
//           shippingPrice: 0,
//         });
//       } else {
//         this.setState({
//           itemsPrice: Math.ceil(total - total * 0.15) + 99,
//           shippingPrice: 99,
//         });
//       }
//     }
//     console.log(wishlistArr)
//   }

//   // handleSelect(date) {
//   //   console.log(date); // native Date object
//   // }

//   searchByDate() {
//     var toStart = new Date(this.state.startDate).toISOString();
//     var sd = new Date(toStart).getTime();
//     var toEnd = new Date(this.state.endDate).toISOString();
//     var ed = new Date(toEnd).getTime();

//     var result = this.state.wishlistArr.filter((wish) => {//this.props.orders.filter((order) 
//         console.log(wish)
//       var time = new Date(wish.createdAt).getTime();
//       return sd < time && time < ed;
//     });
//     console.log("Results", result);
//     this.setState({ filteredArray: result });
//   }

//    async renderTable() {
//      //console.log(this.state.wishlistArr)
//     // console.log(this.state.filteredArray)
//     // const arr =
//     //   this.state.filteredArray.length === 0
//     //     ?await axios.get('http://localhost:5000/wishlist/')
//     //     .then((res)=>{
                        
//     //                     return res.data;
//     //     })//this.props.orders
//     //     : this.state.filteredArray;
//     //     console.log(arr);
//     // let arr =
//     //   this.state.filteredArray.length === 0
//     //     ?this.state.wishlistArr
//     //     : this.state.filteredArray;
//     //     console.log(arr);
        
//     this.state.wishlistArrmap((wish,index)=>{
//                 return(
//                     <tr key={index}>
//                       <td>{wish.user_name}</td>  
//                     </tr>
//                 )
//             })
       
//     // return arr
//     //   .filter((val) => {
//     //     if (this.state.search === "") {
//     //       return val;
//     //     } else if (
//     //       val.user_name
//     //         .toLowerCase()
//     //         .includes(this.state.search.toLowerCase()) ||
//     //       val.user_email
//     //         .toLowerCase()
//     //         .includes(this.state.search.toLowerCase())
//     //     ) {
//     //       // console.log(val._id);
//     //       return val;
//     //     }
//     //   })
//     //   .reverse()
//     //   .map((order, index) => {
//     //     // console.log(
//     //     //   new Date(order.orderInfo.createdAt).toLocaleDateString("en-US")
//     //     // );<tr key={order._id}>
//     //     console.log(order)
//     //     return (
//     //       <tr key={index}>
//     //         <td>{index + 1}</td>
//     //         <td>
//     //           <Moment format=" DD-MMM-YYYY, hh:mm:ss A">
//     //             {order.createdAt}
//     //           </Moment>
//     //         </td>
            
//     //         <td>{order.user_name}</td>
//     //         <td>{order.wishListArr.length}</td>
            
            
            
            
//     //       </tr>
//     //     );
//     //   });
//   }

//   clearValues() {
//     this.setState({
//       startDate: new Date(),
//       endDate: new Date(),
//       filteredArray: [],
//       address: "",
//       city: "",
//       phoneNo: "",
//       pincode: "",
//       country: "",
//       state: "",
//       company: "",
//       username: "",
//       userEmail: "",
//       //  userId: "61b239f77b1a7e100bf71047",
//       userId: "",
//       orderItems: [],
//       payment: {},
//       itemsPrice: "",
//       totalPrice: "",
//       id: "",
//       change: false,
//       paymentMode: "",
//       bgColor: "",
//       discountPrice: "",
//       shippingPrice: "",
//     });
//   }

// //   renderProducts() {
// //     // console.log(JSON.parse(localStorage.getItem("myWishList")));
// //     const products = JSON.parse(localStorage.getItem("myWishList"));
// //     return products.map((item, index) => {
// //       return (
// //         <tr key={item._id}>
// //           <td className="d-flex">
// //             <img src={item.image} alt="Cart Image" width="80px" />
// //             <h5 className="mx-2 my-auto text-capitalize">{item.name}</h5>
// //           </td>
// //           <td>
// //             <span>{item.price}</span>
// //           </td>
// //           <td
// //             className="d-flex align-items-center justify-content-center"
// //             style={{ transform: "translateY(-40%)" }}
// //           >
// //             <input
// //               type="text"
// //               min={0}
// //               name="productCount"
// //               className="text-center customInput"
// //               value={parseInt(item.count)}
// //               onChange={(e) => {
// //                 this.setState({ productCount: e.target.value });
// //               }}
// //               disabled
// //             />
// //           </td>
// //         </tr>
// //       );
// //     });
// //   }

//   // renderExcelData() {
//   //   // Excel Info
//   //   const orders = [];
//   //   const arr =
//   //     this.state.filteredArray.length === 0
//   //       ? this.props.orders
//   //       : this.state.filteredArray;
//   //   arr.map((order) => {
//   //     orders.push({
//   //       "Order Id": "MUG00" + order._id + 1,
//   //       "Invoice Number": "INV00" + order._id + 1,
//   //       "No. of items ordered": order.orderItems.length,
//   //       "Original Price": order.orderInfo.totalPrice,
//   //       "Discount Price (15%)": order.orderInfo.discountPrice,
//   //       "Shipping Price": order.orderInfo.shippingPrice,
//   //       "Total Price": order.orderInfo.itemsPrice,
//   //       "Order Status": order.orderStatus,
//   //       "Payment Mode":
//   //         order.orderInfo.paymentMode === "Cash on Delivery" &&
//   //         order.payment === undefined
//   //           ? "COD"
//   //           : "Online payment",
//   //       "Ordered At":
//   //         new Date(order.orderInfo.createdAt).getDate() +
//   //         "-" +
//   //         parseInt(new Date(order.orderInfo.createdAt).getMonth() + 1) +
//   //         "-" +
//   //         new Date(order.orderInfo.createdAt).getFullYear() +
//   //         " " +
//   //         new Date(order.orderInfo.createdAt).getHours() +
//   //         ":" +
//   //         new Date(order.orderInfo.createdAt).getMinutes() +
//   //         ":" +
//   //         new Date(order.orderInfo.createdAt).getSeconds(),
//   //       "Cancel Reason": order.cancelReason,
//   //       Comments: order.comments,
//   //       Transportation: order.contactInfo,
//   //       "Mode of Transport": order.modeOfTransport,
//   //       "Customer Name": order.user.username,
//   //       "Customer Number": order.user.phoneNo,
//   //       "Customer Email Id": order.user.userEmail,
//   //       "Customer Address":
//   //         order.user.address +
//   //         order.user.company +
//   //         order.user.city +
//   //         order.user.state +
//   //         order.user.country +
//   //         "-" +
//   //         order.user.pincode,
//   //     });
//   //   });
//   //   return orders;
//   // }



// //   renderExcelData() {
// //     // Excel Info
// //     const orders = [];
// //     const arr =
// //       this.state.filteredArray.length === 0
// //         ? this.props.orders
// //         : this.state.filteredArray;
// //     arr.filter((val) => {
// //       if (this.state.search === "") {
// //         return val;
// //       } else if (
// //         val.user_name
// //           .toLowerCase()
// //           .includes(this.state.search.toLowerCase()) ||
       
// //         val.user_email
// //           .toLowerCase()
// //           .includes(this.state.search.toLowerCase())
// //       ) {
// //         // console.log(val._id);
// //         return val;
// //       }
// //     })
// //     .reverse()//
// //     .map((order) => {
// //       console.log(order)
// //       orders.push({
// //         "Order Id": "MUG00" + order._id + 1,
// //         "Invoice Number": "INV00" + order._id + 1,
// //         "No. of items ordered":order.orderItems.length,
// //         "Original Price": order.orderInfo.totalPrice,
// //         "Discount Price (15%)": order.orderInfo.discountPrice,
// //         "Shipping Price": order.orderInfo.shippingPrice,
// //         "Total Price": order.orderInfo.itemsPrice,
// //         "Order Status": order.orderStatus,
// //         "Payment Mode":
// //           order.orderInfo.paymentMode === "Cash on Delivery" &&
// //           order.payment === undefined
// //             ? "COD"
// //             : "Online payment",
// //         "Ordered At":
// //           new Date(order.orderInfo.createdAt).getDate() +
// //           "-" +
// //           parseInt(new Date(order.orderInfo.createdAt).getMonth() + 1) +
// //           "-" +
// //           new Date(order.orderInfo.createdAt).getFullYear() +
// //           " " +
// //           new Date(order.orderInfo.createdAt).getHours() +
// //           ":" +
// //           new Date(order.orderInfo.createdAt).getMinutes() +
// //           ":" +
// //           new Date(order.orderInfo.createdAt).getSeconds(),
// //         "Cancel Reason": order.cancelReason,
// //         Comments: order.comments,
// //         Transportation: order.contactInfo,
// //         "Mode of Transport": order.modeOfTransport,
// //         "Customer Name": order.user.username,
// //         "Customer Number": order.user.phoneNo,
// //         "Customer Email Id": order.user.userEmail,
// //         "Customer Address":
// //           order.user.address +
// //           order.user.company +
// //           order.user.city +
// //           order.user.state +
// //           order.user.country +
// //           "-" +
// //           order.user.pincode,
// //       });
// //     });
// //     return orders;
// //   }



// //   onSubmit(e) {
// //     e.preventDefault();

// //     const userInfo = {
// //       address: this.state.address,
// //       city: this.state.city,
// //       phoneNo: this.state.phoneNo,
// //       pincode: this.state.pincode,
// //       country: this.state.country,
// //       state: this.state.state,
// //       company: this.state.company,
// //       username: this.state.username,
// //       userEmail: this.state.userEmail,
// //     };

// //     const items = JSON.parse(localStorage.getItem("myWishList"));

// //     const paymentInfo = {
// //       itemsPrice: this.state.itemsPrice,
// //       totalPrice: this.state.totalPrice,
// //       shippingPrice: this.state.shippingPrice,
// //       discountPrice: this.state.discountPrice,
// //     };

// //     console.log(paymentInfo);

// //     if (items === null) {
// //       alert("Please add items in Store page");
// //     } else {
// //       trackPromise(
// //         this.props.createOrder({
// //           user: userInfo,
// //           orderItems: JSON.parse(localStorage.getItem("myWishList")),
// //           userId: this.state.userId,
// //           orderInfo: paymentInfo,
// //         })
// //       );
// //     }
// //   }

// //   renderCart() {
// //     const items = { ...localStorage };
// //     const id = JSON.parse(localStorage.getItem("myWishList"))
// //       ? JSON.parse(items.myWishList)
// //       : [];
// //     if (id.length >= 1) {
// //       return (
// //         <div className="container">
// //           <div className="row">
// //             <div className="col-12 col-md-12">
// //               <h5>Products to be ordered:</h5>
// //               <div className="table-responsive">
// //                 <table
// //                   className="table-striped text-center"
// //                   cellPadding="5px"
// //                   width="100%"
// //                 >
// //                   <thead className="greenBg text-white">
// //                     <tr className="p-2 text-center">
// //                       <th>Item</th>
// //                       <th className="mx-2">Rate</th>
// //                       <th>Quantity</th>
// //                     </tr>
// //                   </thead>
// //                   <tbody className="text-center">{this.renderProducts()}</tbody>
// //                 </table>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       );
// //     } else {
// //       return (
// //         <div className="text-center">
// //           <h5 className="text-center text-danger mt-5">
// //             Add products in store page to cart you can see here.
// //           </h5>
// //         </div>
// //       );
// //     }
// //   }

//   render() {
//     return (
//       <div>
//         <AdminHeader />
//         <div className="col-md-10 offset-md-2 ">
//           <h2 className="text-center">Users Wish-List Details</h2>
//           <div className="">
//             <div className="">
//               <div className="form-group">
//                 <label htmlFor="search">Search By User Name or Email ID:</label>
//                 <input
//                   className="form-control"
//                   value={this.state.search}
//                   onChange={(e) => {
//                     this.setState({ search: e.target.value });
//                   }}
//                   placeholder="Search By User Name or Email ID."
//                   id="search"
//                 />
//               </div>

//               <div className="d-flex w-50">
//                 <DatePicker
//                   selected={this.state.startDate}
//                   selectsStart
//                   startDate={this.state.startDate}
//                   endDate={this.state.endDate}
//                   onChange={(date) => {
//                     this.setState({ startDate: date });
//                     console.log(date);
//                   }}
//                   dateFormat="dd/MM/yyyy"
//                 />
//                 <DatePicker
//                   selected={this.state.endDate}
//                   selectsEnd
//                   startDate={this.state.startDate}
//                   // endDate={this.state.endDate}
//                   minDate={this.state.startDate}
//                   onChange={(date) => {
//                     this.setState({ endDate: date });
//                   }}
//                   dateFormat="dd/MM/yyyy"
//                 />
//                 <button
//                   className="btn btn-primary btn-block py-0"
//                   onClick={() => this.searchByDate()}
//                 >
//                   Search by Date
//                 </button>
//               </div>

//               <div className="row">
//                 <div className="col-12 col-md-8">
//                   <div className="input-group mb-3 w-50 my-2">
//                     <div className="input-group-prepend">
//                       <label
//                         className="input-group-text"
//                         HTMLfor="inputGroupSelect01"
//                       >
//                         Search By Order Status
//                       </label>
//                     </div>
//                     <select
//                       className="custom-select"
//                       id="inputGroupSelect01"
//                       value={this.state.search}
//                       onChange={(e) => {
//                         this.setState({ search: e.target.value });
//                       }}
//                     >
//                       <option value="">All</option>
//                       <option value="Pending">Pending</option>
//                       <option value="Processing">Processing</option>
//                       <option value="Shipped">Shipped</option>
//                       <option value="Canceled">Canceled</option>
//                       <option value="Delivered">Delivered</option>
//                     </select>
//                   </div>
//                 </div>
//                 {/* <DateRangePicker
//                   ranges={[selectionRange]}
//                   onChange={this.handleSelect}
//                 /> */}

//                 <div className="col-12 col-md-4 text-center">
//                   {/* <ExportToExcel
//                     apiData={this.renderExcelData()}
//                     fileName={this.state.fileName}
//                   /> */}
//                    {/* <ExportToExcel
//                     apiData={this.renderTable()}
//                     fileName={this.state.fileName}
//                   /> */}

//                   <button
//                     className="btn btn-secondary mt-2"
//                     data-toggle="modal"
//                     data-target="#exampleModal"
//                   >
//                     Add order
//                   </button>
//                 </div>
//               </div>

//               <table className="table table-striped  styled-table text-center">
//                 <thead>
//                   <tr>
//                     <th>Sl. No</th>
//                     <th>Date</th>
//                     <th>Order Id</th>
//                     <th>Invoice No.</th>
//                     <th>Name</th>
//                     <th>No. of Items</th>
//                     <th>Amount</th>
//                     <th>Payment Mode</th>
//                     <th>Status</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>{this.renderTable()}</tbody>
//                 {/* <tbody onClick={this.renderTable.bind(this)}>Click Here</tbody> */}
//               </table>
//             </div>
//           </div>
//         </div>

//         {/* modal comes here */}
//         <form method="post" onSubmit={this.onSubmit.bind(this)}>
//           <div
//             className="modal fade"
//             id="exampleModal"
//             tabIndex="-1"
//             role="dialog"
//             aria-labelledby="exampleModalLabel"
//             aria-hidden="true"
//             data-backdrop="static"
//           >
//             <div
//               className="modal-dialog modal-lg  modal-dialog-centered"
//               role="document"
//             >
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h3 className="modal-title" id="exampleModalLabel">
//                     Add Order
//                   </h3>
//                   <button
//                     type="button"
//                     className="close"
//                     data-dismiss="modal"
//                     aria-label="Close"
//                     onClick={() => this.clearValues()}
//                     style={{ transform: "translate(-100%,80%)" }}
//                   >
//                     <span aria-hidden="true">&times;</span>
//                   </button>
//                 </div>
//                 <div className="modal-body modal_body">
//                   {this.renderCart()}
//                   <div className="row">
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer Name <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="username"
//                           className="form-control products_input"
//                           value={this.state.username}
//                           onChange={(e) => {
//                             this.setState({ username: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer Address{" "}
//                           <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="username"
//                           className="form-control products_input"
//                           value={this.state.address}
//                           onChange={(e) => {
//                             this.setState({ address: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer City <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="city"
//                           className="form-control products_input"
//                           value={this.state.city}
//                           onChange={(e) => {
//                             this.setState({ city: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer phoneNo{" "}
//                           <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="number"
//                           name="phoneNo"
//                           className="form-control products_input"
//                           value={this.state.phoneNo}
//                           onChange={(e) => {
//                             this.setState({ phoneNo: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer pincode{" "}
//                           <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="pincode"
//                           className="form-control products_input"
//                           value={this.state.pincode}
//                           onChange={(e) => {
//                             this.setState({ pincode: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer country{" "}
//                           <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="country"
//                           className="form-control products_input"
//                           value={this.state.country}
//                           onChange={(e) => {
//                             this.setState({ country: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer company{" "}
//                           <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="company"
//                           className="form-control products_input"
//                           value={this.state.company}
//                           onChange={(e) => {
//                             this.setState({ company: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer Email <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="userEmail"
//                           className="form-control products_input"
//                           value={this.state.userEmail}
//                           onChange={(e) => {
//                             this.setState({ userEmail: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-12 col-md-6">
//                       <div className="form-group">
//                         <label>
//                           Customer state <span className="text-danger">*</span>
//                         </label>
//                         <input
//                           type="text"
//                           name="state"
//                           className="form-control products_input"
//                           value={this.state.state}
//                           onChange={(e) => {
//                             this.setState({ state: e.target.value });
//                           }}
//                           autoComplete="off"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="modal-footer">
//                   <button
//                     type="button"
//                     className="btn  btn-danger"
//                     data-dismiss="modal"
//                     onClick={() => this.clearValues()}
//                   >
//                     Close
//                   </button>
//                   <input
//                     type="submit"
//                     value="Add Order"
//                     className="btn btn-success"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </form>
//         {/* modal ends */}
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     orders: state.orders,
//   };
// };

// export default connect(mapStateToProps, { fetchOrders, createOrder })(
//   WishlistDetails
// );


import React, { Component,useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import { DateRangePicker } from "react-date-range";
import $ from "jquery";
// import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import { fetchOrders, createOrder } from "../../../actions/paymentActions";
import AdminHeader from "../AdminHeader";
import { ExportToExcel } from "../Orders/ExportToExcel";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios from 'axios';

function WishlistDetails(){
    const [update,setUpdate] = useState(true);
    const [search,setSearch] = useState("");
    const [fileName,setFileName] = useState(
        "MUG Sales Report " +
              new Date().getDate() +
              "-" +
              parseInt(new Date().getMonth() + 1) +
              "-" +
              new Date().getFullYear() +
              " " +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds()
                                            );
    const [data,setData] = useState({});
    const [startDate,setStartDate] = useState(new Date());
    const [endDate,setEndDate] = useState(new Date());
    const [filteredArray,setFilteredArray] = useState([]);
    const [address ,setAddress] = useState();
    const [city ,setCity] = useState();
    const [phoneNo ,setPhoneNo] = useState();
    const [pincode ,setPincode] = useState();
    const [country ,setCountry] = useState();
    const [state,setState] = useState();
    const [company,setCompany] = useState();
    const [username,setUsername] = useState();
    const [userEmail,setUserEmail] = useState();
    const [userId,setUserId] = useState();
    const [orderItems,setOrderItems] = useState([]);
    const [payment,setPayment] = useState([]);
    const [itemsPrice,setItemsPrice] = useState();
    const [totalPrice,setTotalPrice] = useState();
    const [id,setId] = useState();
    const [change,setChange] = useState(false);
    const [paymenyMode,setPaymentMode] = useState();
    const [bgColor,setBgColor] = useState();
    const [discountPrice,setDiscountPrice] = useState();
    const [shippingPrice,setShippingPrice] = useState();
    const [wishListArray,setWishListArray] = useState([]);

        useEffect(()=>{
            axios.get('/wishlist/')
                        .then((res)=>{
                                        //console.log(res);
                                        let arry=res.data;
                                        //console.log(arry);
                                        sessionStorage.setItem("userswishlist",JSON.stringify(res.data))
                                        setWishListArray(res.data);
                                        
                        }).catch((error)=>{console.log(error)}
                                 )
        },[])
    const searchByNameEmail =()=>{
        // console.log(search)
        // console.log(wishListArray)
        let newSearch = search.toLowerCase();
        //console.log(newSearch)
      let newArray =  wishListArray.filter((valu) => {
                    console.log(valu.user_name.toLowerCase());
                    let lowerCasename= valu.user_name.toLowerCase();
                    let lowerCaseemail= valu.user_email.toLowerCase();
            if (search === "") {
                
                return valu;
              } else if (
                
                lowerCasename.includes(newSearch)||
                lowerCaseemail.includes(newSearch)
      
                //valu.name.toLowerCase() == search.toLowerCase()
                //valu.email.toLowerCase() === search.toLowerCase()
                // valu.email.toLowerCase().includes(search.toLowerCase())
              ) {
      
                 //console.log("search");
                
                return valu;
              }
            })
            console.log(newArray)
            if(newArray.length!=0){
                setWishListArray(newArray);
            }else{
                return ; 
            }
            
               }
    const searchByDate =()=>{
                var toStart = new Date(startDate).toISOString();
                var sd = new Date(toStart).getTime();
                var toEnd = new Date(endDate).toISOString();
                var ed = new Date(toEnd).getTime();
           
                var result = wishListArray.filter((wish) => {
                    console.log(wish)
                  var time = new Date(wish.createdAt).getTime();
                  return sd < time && time < ed;
                });
                //console.log("Results", result);
               if(result.length>=1){
                setFilteredArray(result)
                   return ;
               } else{
                   alert("No wishlist between these dates")
               }
                
              }

        // const exportToExcel=()=> {
        //         var location = 'data:application/vnd.ms-excel;base64,';
        //         var excelTemplate = '<html> ' +
        //             '<head> ' +
        //             '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
        //             '</head> ' +
        //             '<body> ' +
        //             document.getElementById("table-conatainer").innerHTML +
        //             '</body> ' +
        //             '</html>'
        //         window.location.href = location + window.btoa(excelTemplate);
        //     }
    return(
        <div>
            <div>
         <AdminHeader />
         <div className="col-md-10 offset-md-2 ">
           <h2 className="text-center">Users Wish-List Details</h2> 
           <div className=""> 
             <div className=""> 
               <div className="form-group "> 
                 <label htmlFor="search">Search By User Name or Email ID:</label> 
                 <input
                  className="form-control col-6"
                  value={search}
                  onInput={(e) => {
                    setSearch(e.target.value);
                    
                    
                  }}
                  placeholder="Search By User Name or Email ID."
                  id="search"
                /><br />
                <button
                  className="btn btn-primary btn-block py-1 col-3"
                  onClick={() =>{searchByNameEmail()}}
                >
                  Search 
                </button>
              </div><br />

              

              <div className="row">
                <div className="col-12 row justify-content-between" >
                  {/* <div className="input-group mb-3 w-50 my-2">
                    <div className="input-group-prepend">
                      <label
                        className="input-group-text"
                        HTMLfor="inputGroupSelect01"
                      >
                        Search By Order Status
                      </label>
                    </div>
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      value={search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value });
                      }}
                    >
                      <option value="">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Processing">Processing</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Canceled">Canceled</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                    
                  </div> */}
                </div>
                {/* <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                /> */}
                    <div className="d-flex w-50 col-12 col-md-6" >
                <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(date) => {
                    setStartDate( date );
                    console.log(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                />
                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  // endDate={endDate}
                  minDate={startDate}
                  onChange={(date) => {
                    setEndDate(date) 
                  }}
                  dateFormat="dd/MM/yyyy"
                />
                <button
                  className="btn btn-primary btn-block py-0"
                  onClick={() =>searchByDate()}
                >
                  Search by Date
                </button>
                
              </div>
                <div className="col-12 col-md-2 offset-md-4 text-center" >
                    {/* <ExportToExcel
                    apiData={this.renderExcelData()}
                    fileName={fileName}
                  /> */}
                  {/* <ExportToExcel
                    
                    fileName={fileName}
                  /> */}
                    {/* <button onClick={exportToExcel}>
                        Click to Export
                    </button> */}
                   {/* <ExportToExcel
                    apiData={wishListArray}
                    fileName={fileName}
                  /> */}

                  {/* <button
                    className="btn btn-secondary mt-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Add order
                  </button> */}
                  <ReactHTMLTableToExcel
                    id="table-xls-button"
                    className="btn btn-success btn-block py-1.5"
                    table="table-conatainer"
                    filename="Wish-List details"
                    sheet="Wish-List details"
                    buttonText="Download Reports"
                   
                    ></ReactHTMLTableToExcel>
                </div>
                
              </div><br />

              <table className="table table-striped  styled-table text-center" id="table-conatainer">
                <thead>
                  <tr>
                    <th>Sl. No</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone No</th>
                    <th>No. of Items</th>
                    
                    <th>Actions</th>
                  </tr>
                </thead>
                {/* <tbody>{this.renderTable(){wishListArray.map((wish,index)=>{
                            return(
                                <tr key={index}>
                                <td>{wish.user_name}</td>  
                                </tr>
                            )
                        })}}</tbody> */}
                <tbody>{filteredArray.length >=1?filteredArray
                // .filter((val) => {
                    
                //                if (search === "") {
                //                  return val;
                //                } else if (
                //                  val.user_name
                //                    .toLowerCase()
                //                    .includes(search.toLowerCase()) ||
                //                  val.user_email
                //                    .toLowerCase()
                //                    .includes(search.toLowerCase())
                //                  ) {
                //                     //console.log(val._id);
                //                    return val;
                //                  }
                //                })
                               .map((wish,index)=>{
                    if(wish.wishListArray.length>=1)
                    {
                        return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                             <td>
                               <Moment format=" DD-MMM-YYYY, hh:mm:ss A">
                                 {wish.createdAt}
                               </Moment>
                             </td>
            
                             <td>{wish.user_name}</td>
                             <td>{wish.user_email}</td>
                             <td>{wish.user_mobile}</td>
                             <td>{wish.wishListArray.length}</td>
                             
                             <td>
                                    <ul
                                        className="d-flex justify-content-around w-75 my-auto"
                                        style={{ listStyle: "none" }}
                                    >
                                        <li>
                                        <a
                                             href={`/singleuserwishlistdetails/621eecbf1b5e916ea58b1b73`}
                                            className="btn btn-sm btn-primary "
                                        >
                                            <i className="fa fa-eye" style={{color:"#fff"}}></i>
                                        </a>
                                        </li>
                                        {/* <li>
                                        <a
                                        style={{ cursor: "pointer" }}
                                        className="btn btn-sm btn-danger text-white"
                                        >
                                        <i className="fa fa-trash"></i>
                                        </a>
                                    </li> */}
                                    </ul>
                            </td>
                        </tr>
                    )} else{
                        return ;
                    }
                })
                :wishListArray
                // .filter((valu) => {
                    
                //     if (search === "") {
                        
                //         return valu;
                //       } else if (
                //         valu.user_name.toLowerCase().includes(search.toLowerCase())||
                //         valu.email.toLowerCase().includes(search.toLowerCase())
              
                //         //valu.name.toLowerCase() == search.toLowerCase()
                //         //valu.email.toLowerCase() === search.toLowerCase()
                //         // valu.email.toLowerCase().includes(search.toLowerCase())
                //       ) {
              
                //          //console.log("search");
                //         return valu;
                //       }
                //     })
                    .map((wish,index)=>{
                    if(wish.wishListArray.length>=1)
                    {
                        const userId= wish.user_id;
                        return (
                        <tr key={index}>
                            <td ><span className="sl-no"></span></td>
                             <td>
                               <Moment format=" DD-MMM-YYYY, hh:mm:ss A">
                                 {wish.createdAt}
                               </Moment>
                             </td>
            
                             <td>{wish.user_name}</td>
                             <td>{wish.user_email}</td>
                             <td>{wish.user_mobile}</td>
                             <td>{wish.wishListArray.length}</td>
                             
                             <td>
                                    <ul
                                        className="d-flex justify-content-around w-75 my-auto"
                                        style={{ listStyle: "none" }}
                                    >
                                        <li>
                                        <a
                                             href={`/singleuserwishlistdetails?id=${userId}`}///621eecbf1b5e916ea58b1b73
                                            className="btn btn-sm btn-primary "
                                        >
                                            <i className="fa fa-eye" style={{color:"#fff"}}></i>
                                        </a>
                                        </li>
                                        {/* <li>
                                        <a
                                        style={{ cursor: "pointer" }}
                                        className="btn btn-sm btn-danger text-white"
                                        >
                                        <i className="fa fa-trash"></i>
                                        </a>
                                    </li> */}
                                    </ul>
                            </td>
                        </tr>
                    )} else{
                        return ;
                    }
                })
                }</tbody>
                
              </table>
            </div>
          </div>
        </div>
        </div>
        </div>
        
    
    )
}

export default WishlistDetails;