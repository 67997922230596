import React from "react";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import PropTypes from "prop-types";

import {
  createHeading,
  fetchHeadings,
  updateHeading,
  deleteHeading,
} from "../../../actions/HeadingActions";
import AdminHeader from "../AdminHeader";
import FileBase64 from "react-file-base64";

import axios from 'axios';
import { object } from "joi";

class Headings extends React.Component {
  state = {
    id: "",
    home1: "",
    home2: "",
    homeheading1:"",
    homeheading2:"",
    homeheading_id:"",
    homeheading_data:[],
  };

  componentDidMount() {
    trackPromise(this.props.fetchHeadings());
    axios.get('/headings1').then((res)=>{
        console.log(res.data);
        this.setState({homeheading_data:res.data});
        this.setState({homeheading1:`${res.data[0].home1}`,homeheading2:`${res.data[0].home2}`})
    }).catch((error)=>{
      console.log("error",error)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.id !== prevState.id) {
      const value = this.props.headings.filter(
        (val) => val._id === this.state.id
      );
      this.setState(value[0]);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    // console.log(this.state);
    if (this.props.headings.length >= 2) {
      alert(
        "You can add only two forms. If you want to add please delete and add again."
      );
    } else {
      trackPromise(this.props.createHeading(this.state));
      this.setState({
        home1: "",
        home2: "",
        
      });
    }
  }

  clearValues() {
    this.setState({
      home1: "",
      home2: "",
      
    });
  }

  updateForm(e) {
    e.preventDefault();
    trackPromise(this.props.updateHeading(this.state.id, this.state));
    // console.log(this.state);
  }
  updateHeading(e) {
    e.preventDefault();
    
    let objectOb={
      home1: this.state.homeheading1,
      home2: this.state.homeheading2,
    }
    axios.patch(`/headings1/${this.state.homeheading_id}`,objectOb).then((res)=>{
      //console.log(res.data);
      
      
  }).catch((error)=>{
    console.log("error",error)
  })
  }
  renderTable() {
    return this.props.headings.map((heading, index) => {
      return (
        <tr key={heading._id}>
          <td>{index + 1}</td>
          <td>
            <h4>{heading.home1}</h4>
          </td>
          <td>
            <h4>{heading.home2}</h4>
          </td>
          
          <td>
            <a
              data-toggle="modal"
              data-target="#exampleModal"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ id: heading._id });
              }}
            >
              <i className="fa fa-pen mt-2"></i>
            </a>
          </td>
          {/* {<td>
            <a
              onClick={() => {
                if (window.confirm("Do you want to delete headings?")) {
                  trackPromise(this.props.deleteHeading(heading._id));
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-trash mt-2"></i>
            </a>
          </td>} */}
        </tr>
      );
    });
  }

  renderHeadingTable() {
    return this.state.homeheading_data.map((heading, index) => {
      return (
        <>
        <tr key={heading._id}>
          <td>{index + 1}</td>
          <td>
            <h4>{heading.home1}</h4>
          </td>
          
          
          <td>
            <a
              data-toggle="modal"
              data-target="#exampleModal1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ homeheading_id: heading._id });
              }}
            >
              <i className="fa fa-pen mt-2"></i>
            </a>
          </td>
          {/* {<td>
            <a
              onClick={() => {
                if (window.confirm("Do you want to delete headings?")) {
                  trackPromise(this.props.deleteHeading(heading._id));
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-trash mt-2"></i>
            </a>
          </td>} */}
        </tr>
        <tr key={heading._id}>
          <td>{index + 2}</td>
          
          <td>
            <h4>{heading.home2}</h4>
          </td>
          
          <td>
            <a
              data-toggle="modal"
              data-target="#exampleModal2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ homeheading_id: heading._id });
              }}
            >
              <i className="fa fa-pen mt-2"></i>
            </a>
          </td>
          {/* {<td>
            <a
              onClick={() => {
                if (window.confirm("Do you want to delete headings?")) {
                  trackPromise(this.props.deleteHeading(heading._id));
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-trash mt-2"></i>
            </a>
          </td>} */}
        </tr>
        </>
        
      );
    });
  }


  handleKeyDown(e) {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  render() {
    // console.log(this.props.headings);
    return (
      <div>
        <AdminHeader />

        <div className="col-md-10 offset-md-2 ">
          <div className="op-header">
            <div className="section-header text-center">
              <h1 className="f2 c3">Discounts And Headings</h1>
              {/* <h2 className="f2 c3">Select Category</h2> */}
              <p>NOTE:You can add only two discounts</p>
            </div>
          </div>
          <br />
          <div className="container">
          {/* {<h1 className="f2 c3">Discounts </h1>
            <form action="" onSubmit={this.onSubmit.bind(this)}>
              <div className="form-group">
                <label>
                  Discount %<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex: 15% off*"
                  required
                  value={this.state.home1}
                  onChange={(e) => {
                    this.setState({ home1: e.target.value });
                  }}
                />
              </div>

              <div className="form-group">
                <label>
                  Description  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex: Avail this offer on your first purchase."
                  required
                  value={this.state.home2}
                  onChange={(e) => {
                    this.setState({ home2: e.target.value });
                  }}
                />
              </div>

              
              <input
                type="submit"
                value="Submit"
                className="btn py-n3 btn-primary "
              />
            </form>

            
            <br />
            <br />
            <br />
            <h1 className="f2 c3">Headings</h1>
            <form action="" onSubmit={this.onSubmit.bind(this)}>
              <div className="form-group">
                <label>
                  Heading 1<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex: 15% off*"
                  required
                  value={this.state.homeheading1}
                  onChange={(e) => {
                    this.setState({ homeheading1: e.target.value });
                  }}
                />
              </div>

              <div className="form-group">
                <label>
                  Heading 2  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex: On"
                  required
                  value={this.state.homeheading2}
                  onChange={(e) => {
                    this.setState({ homeheading2: e.target.value });
                  }}
                />
              </div>

              
              <input
                type="submit"
                value="Submit"
                className="btn py-n3 btn-primary "
              />
            </form>
            <br />
            <br />
            <br />
            <br />} */}
            <div className="table-responsive">
              <h1>Discounts</h1>
              <table className="table table-striped styled-table text-center">
                <thead>
                  <tr>
                    <th>Sl. No</th>
                    <th>Discount %</th>
                    <th>Description</th>
                    
                    <th>Edit</th>
                    {/* {<th>Delete</th>} */}
                  </tr>
                </thead>
                <tbody>{this.renderTable()}</tbody>
              </table>
            </div>

            <br />
            <div className="table-responsive">
            <h1>Headings</h1>
              <table className="table table-striped styled-table text-center">
                <thead>
                  <tr>
                    <th>Sl. No</th>
                    <th>heading</th>
                    
                    
                    <th>Edit</th>
                    {/* {<th>Delete</th>} */}
                  </tr>
                </thead>
                <tbody>{this.renderHeadingTable()}</tbody>
              </table>
            </div>
          </div>
        </div>

        

        {/* modal comes here */}
        <form method="post" onSubmit={this.updateForm.bind(this)}>
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div
              className="modal-dialog modal-lg  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Edit Details
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.clearValues()}
                    style={{ transform: "translate(-100%,80%)" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>
                      Discount % <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex: 15% off*"
                      required
                      value={this.state.home1}
                      onChange={(e) => {
                        this.setState({ home1: e.target.value });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex: Avail this offer on your first purchase."
                      required
                      value={this.state.home2}
                      onChange={(e) => {
                        this.setState({ home2: e.target.value });
                      }}
                    />
                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn  btn-danger"
                    data-dismiss="modal"
                    onClick={() => this.clearValues()}
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save Changes"
                    className="btn btn-success"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <form method="post" onSubmit={this.updateHeading.bind(this)}>
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div
              className="modal-dialog modal-lg  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Edit heading1 Details
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.clearValues()}
                    style={{ transform: "translate(-100%,80%)" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>
                      heading1  <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex: 15% off*"
                      required
                      value={this.state.homeheading1}
                      onChange={(e) => {
                        this.setState({ homeheading1: e.target.value });
                      }}
                    />
                  </div>


                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn  btn-danger"
                    data-dismiss="modal"
                    onClick={() => this.clearValues()}
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save Changes"
                    className="btn btn-success"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        
        <form method="post" onSubmit={this.updateHeading.bind(this)}>
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel2"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div
              className="modal-dialog modal-lg  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Edit heading2 Details
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.clearValues()}
                    style={{ transform: "translate(-100%,80%)" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  

                  <div className="form-group">
                    <label>
                      heading2<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex: Avail this offer on your first purchase."
                      required
                      value={this.state.homeheading2}
                      onChange={(e) => {
                        this.setState({homeheading2: e.target.value });
                      }}
                    />
                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn  btn-danger"
                    data-dismiss="modal"
                    onClick={() => this.clearValues()}
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save Changes"
                    className="btn btn-success"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        {/* modal ends */}
      </div>
    );
  }
}

Headings.propTypes = {
  aws_s3_image_url: PropTypes.string,
  msg: PropTypes.string,
  type: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    headings: state.headings,
  };
};

export default connect(mapStateToProps, {
  createHeading,
  fetchHeadings,
  updateHeading,
  deleteHeading,
})(Headings);
