import {
    // FETCH_PRODUCTS,
    // UPDATE_PRODUCT,
    // DELETE_PRODUCT,
    // ADD_PRODUCT,
    // FETCH_DAILY,
    UPDATE_COUNT_WISHLIST_NUM,
  } from "../actions/type";
  
  const items = { ...localStorage };
  const ids = JSON.parse(localStorage.getItem("myWishList"))
    ? JSON.parse(items.myWishList)
    : [];
  export default (state = { count: ids.length }, action) => {
    switch (action.type) {
      case UPDATE_COUNT_WISHLIST_NUM:
           //console.log("calling",ids.length);
           //console.log(action.payload)
        return { ...state, count: action.payload };
      //   case ADD_PRODUCT:
      //     return [...state, action.payload];
      //   case UPDATE_PRODUCT:
      //     return state.map((post) =>
      //       post._id === action.payload._id ? action.payload : post
      //     );
      //   case DELETE_PRODUCT:
      //     return state.filter((state) => state._id !== action.payload);
  
      default:
        return state;
    }
  };
  