import axios from 'axios';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { serialize } from 'object-to-formdata';

const CCavenuepay = ({ component: Component, ...rest }) => {
  const handleSubmit = async e => {
    e.preventDefault();
    // alert('called');

    const values = {
      order_id: 12345678,
      currency: 'INR',
      amount: '1.00',
      merchant_id: '2435312',
      redirect_url: 'https://www.multiplexurbangreen.com/ccavResponseHandler',
      cancel_url: 'https://www.multiplexurbangreen.com/ccavResponseHandler',
      language: 'EN',
      billing_name: 'Sibin',
      billing_tel: '9074033910',
    };

    const formData = serialize(values, {
      nullsAsUndefineds: true,
      noFilesWithArrayNotation: true,
      indices: true,
    });
    await axios.post('http://localhost:1338/ccavRequestHandler', formData);
  };
  return (
    <>
      <form
        method='POST'
        name='customerData'
        // action='http://localhost:1338/ccavRequestHandler'
        // action='/ccavRequestHandler'
        onSubmit={handleSubmit}
      >
        <table width='40%' height='100' border='1' align='center'>
          <caption>
            <font size='4' color='blue'>
              <b>Integration Kit</b>
            </font>
          </caption>
        </table>
        <table width='40%' height='100' border='1' align='center'>
          <tr>
            <td>Parameter Name:</td>
            <td>Parameter Value:</td>
          </tr>
          <tr>
            <td colspan='2'>Compulsory information</td>
          </tr>
          <tr>
            <td>Merchant Id</td>
            <td>
              <input type='text' name='merchant_id' id='merchant_id' value='2435312' />{' '}
            </td>
          </tr>
          <tr>
            <td>Order Id</td>
            <td>
              <input type='text' name='order_id' value='1234' />
            </td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>
              <input type='text' name='currency' value='INR' />
            </td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>
              <input type='text' name='amount' value='1.00' />
            </td>
          </tr>
          <tr>
            <td>Redirect URL</td>
            <td>
              <input
                type='text'
                name='redirect_url'
                value='https://www.multiplexurbangreen.com/ccavResponseHandler'
              />
            </td>
          </tr>
          <tr>
            <td>Cancel URL</td>
            <td>
              <input
                type='text'
                name='cancel_url'
                value='https://www.multiplexurbangreen.com/ccavResponseHandler'
              />
            </td>
          </tr>
          <tr>
            <td>Language</td>
            <td>
              <input type='text' name='language' id='language' value='EN' />
            </td>
          </tr>
          <tr>
            <td colspan='2'>Billing information(optional):</td>
          </tr>
          <tr>
            <td>Billing Name</td>
            <td>
              <input type='text' name='billing_name' value='Peter' />
            </td>
          </tr>
          <tr>
            <td>Billing Address:</td>
            <td>
              <input type='text' name='billing_address' value='Santacruz' />
            </td>
          </tr>
          <tr>
            <td>Billing City:</td>
            <td>
              <input type='text' name='billing_city' value='Mumbai' />
            </td>
          </tr>
          <tr>
            <td>Billing State:</td>
            <td>
              <input type='text' name='billing_state' value='MH' />
            </td>
          </tr>
          <tr>
            <td>Billing Zip:</td>
            <td>
              <input type='text' name='billing_zip' value='400054' />
            </td>
          </tr>
          <tr>
            <td>Billing Country:</td>
            <td>
              <input type='text' name='billing_country' value='India' />
            </td>
          </tr>
          <tr>
            <td>Billing Tel:</td>
            <td>
              <input type='text' name='billing_tel' value='9876543210' />
            </td>
          </tr>
          <tr>
            <td>Billing Email:</td>
            <td>
              <input type='text' name='billing_email' value='testing@domain.com' />
            </td>
          </tr>
          <tr>
            <td colspan='2'>Shipping information(optional):</td>
          </tr>
          <tr>
            <td>Shipping Name</td>
            <td>
              <input type='text' name='delivery_name' value='Sam' />
            </td>
          </tr>
          <tr>
            <td>Shipping Address:</td>
            <td>
              <input type='text' name='delivery_address' value='Vile Parle' />
            </td>
          </tr>
          <tr>
            <td>Shipping City:</td>
            <td>
              <input type='text' name='delivery_city' value='Mumbai' />
            </td>
          </tr>
          <tr>
            <td>Shipping State:</td>
            <td>
              <input type='text' name='delivery_state' value='Maharashtra' />
            </td>
          </tr>
          <tr>
            <td>Shipping Zip:</td>
            <td>
              <input type='text' name='delivery_zip' value='400038' />
            </td>
          </tr>
          <tr>
            <td>Shipping Country:</td>
            <td>
              <input type='text' name='delivery_country' value='India' />
            </td>
          </tr>
          <tr>
            <td>Shipping Tel:</td>
            <td>
              <input type='text' name='delivery_tel' value='0123456789' />
            </td>
          </tr>
          <tr>
            <td>Merchant Param1</td>
            <td>
              <input type='text' name='merchant_param1' value='additional Info.' />
            </td>
          </tr>
          <tr>
            <td>Merchant Param2</td>
            <td>
              <input type='text' name='merchant_param2' value='additional Info.' />
            </td>
          </tr>
          <tr>
            <td>Merchant Param3</td>
            <td>
              <input type='text' name='merchant_param3' value='additional Info.' />
            </td>
          </tr>
          <tr>
            <td>Merchant Param4</td>
            <td>
              <input type='text' name='merchant_param4' value='additional Info.' />
            </td>
          </tr>
          <tr>
            <td>Merchant Param5</td>
            <td>
              <input type='text' name='merchant_param5' value='additional Info.' />
            </td>
          </tr>
          <tr>
            <td>Promo Code:</td>
            <td>
              <input type='text' name='promo_code' value='' />
            </td>
          </tr>
          <tr>
            <td>Customer Id:</td>
            <td>
              <input type='text' name='customer_identifier' value='' />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <input TYPE='submit' value='Checkout'></input>
            </td>
          </tr>
        </table>
      </form>
    </>
  );
};

export default CCavenuepay;
