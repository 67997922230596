import React, { Component } from "react";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";

import Footer from "./Footer/Footer";
import BestSeller from "./IndexPage/BestSeller";
import DailyDeals from "./IndexPage/DailyDeals";
import LatestBlogs from "./IndexPage/LatestBlogs";
import MainCarousel from "./IndexPage/MainCarousel";
import Shipment from "./IndexPage/Shipment";
import Testimonial from "./IndexPage/Testimonial";
import Header from "./Navbar/Header";
import { Link } from "react-router-dom";
import WishlistLink from "./Wishlist/WishlistLink";
import CartLink from "./Cart/CartLink";
import Collaboration from "./IndexPage/Collaboration";
import { AddVisitors } from "../actions/VisitorsActions";

import axios from 'axios';

class IndexBody extends Component {
  state={
    Wishlist_count:JSON.parse(localStorage.getItem("myWishList"))
                  ? JSON.parse(localStorage.getItem("myWishList")).length
                  :0,
  }

  componentDidMount() {
    document.title =
      "Online urban Garden Store, Seeds Agricultural Products | MultiplexUrbanGreen ";

    if (!localStorage.getItem("uGreenVisitor")) {
      trackPromise(this.props.AddVisitors());
      if(localStorage.profile && localStorage.myWishList){
      
        this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList"))
        ? JSON.parse(localStorage.getItem("myWishList")).length
        : "",
        //myWishList:JSON.parse(localStorage.getItem("myWishList")),
          })//checkwishlist
          localStorage.setItem("wishlength",JSON.parse(localStorage.getItem("myWishList")).length);
      }else if(localStorage.profile && !localStorage.myWishList){
        
        let userId = JSON.parse(localStorage.getItem("profile")).user._id
        axios.get(`/wishlist/checkwishlist/${userId}`).then((wish)=>{
                    console.log(wish.data.data.wishListArray);
                  localStorage.setItem("myWishList",JSON.stringify(wish.data.data.wishListArray));
                  localStorage.setItem("wishlength",JSON.stringify(wish.data.data.wishListArray.length))
                  //alert(JSON.parse(localStorage.getItem("myWishList")).length)
                  this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList").length),})
        }).catch((error)=>{console.log(error)})
            
      }else if(localStorage.myWishList){
        this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList").length)})
        localStorage.setItem("wishlength",JSON.parse(localStorage.getItem("myWishList")).length);
          
      } else{
        return ;
      }
    } else{
      if(localStorage.profile && localStorage.myWishList){
      
        this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList"))
        ? JSON.parse(localStorage.getItem("myWishList")).length
        : "",
        //myWishList:JSON.parse(localStorage.getItem("myWishList")),
          })//checkwishlist
          localStorage.setItem("wishlength",JSON.parse(localStorage.getItem("myWishList")).length);
          //alert(JSON.parse(localStorage.getItem("myWishList")).length)
      }else if(localStorage.profile && !localStorage.myWishList){
        
        let userId = JSON.parse(localStorage.getItem("profile")).user._id
        axios.get(`/wishlist/checkwishlist/${userId}`).then((wish)=>{
                    console.log(wish.data.data.wishListArray);
                  localStorage.setItem("myWishList",JSON.stringify(wish.data.data.wishListArray));
                  localStorage.setItem("wishlength",JSON.stringify(wish.data.data.wishListArray.length))
                  //alert(JSON.parse(localStorage.getItem("myWishList")).length)
                  this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList").length),})
        }).catch((error)=>{console.log(error)})
            
      }else if(localStorage.myWishList){
        this.setState({Wishlist_count:JSON.parse(localStorage.getItem("myWishList").length)})
        localStorage.setItem("wishlength",JSON.parse(localStorage.getItem("myWishList")).length);
          
      } else{
        return ;
      }

    }
    
    
  }

  render() {
    return (
      <div>
        <div className="background_img">
          <Header />
          <MainCarousel />
        </div>
        <CartLink />
       {/* <WishlistLink /> */}
        <Shipment />
        <DailyDeals />
        <BestSeller />
        <Testimonial />
        <Collaboration />
        <LatestBlogs />
        <Footer />
      </div>
    );
  }
}

export default connect(null, { AddVisitors })(IndexBody);
